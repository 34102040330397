import type { ComponentType } from "react"
import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"

// Learn more: https://www.framer.com/docs/guides/overrides/

const useStore = createStore({
    background: "#0099FF",
})

export function withDiadaSemana(Component): ComponentType {
    return (props) => {
        const urls = [
            "https://youtu.be/OSDeuVRAPlc",
            "https://youtu.be/4yIfa4AN8TQ",
            "https://youtu.be/kEDv6kFg8Z4",
            "https://youtu.be/V01O78xdO8I",
            "https://youtu.be/yQU9brUkTgE",
            "https://youtu.be/5NZp5ClLoDc",
            "https://youtu.be/3--WCj1CYAQ",
        ]

        const today = new Date().getDay()

        const videoUrl = urls[today]

        return <Component {...props} url={videoUrl} />
    }
}
